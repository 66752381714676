@gray-dark:                     var(--fbo-theme-gray-dark, lighten(#000, 20%));
@gray:                          var(--fbo-theme-gray, lighten(#000, 33.5%));
@gray-light:                    var(--fbo-theme-gray-light, lighten(#000, 60%));
@gray-lighter:                  var(--fbo-theme-gray-lighter, lighten(#000, 93.5%));
@brand-primary:                 var(--fbo-theme-brand-primary, #428bca);
@brand-success:                 var(--fbo-theme-brand-success, #5cb85c);
@brand-warning:                 var(--fbo-theme-brand-warning, #f0ad4e);
@brand-danger:                  var(--fbo-theme-brand-danger, #d9534f);
@brand-info:                    var(--fbo-theme-brand-info, #5bc0de);
@brand-test:                    var(--fbo-theme-brand-test, #fcf8e3);

@primary-color:                 var(--fbo-theme-button-primary-color, #1E64E1);

@body-bg:                       var(--fbo-theme-body-background, #fff);
@text-color:                    @gray-dark;

@link-color:                    var(--fbo-theme-link-color, #428bca);
@link-hover-color:              var(--fbo-theme-link-hover-color, darken(color(#428bca), 15%));

@font-family-sans-serif:        var(--fbo-theme-font-family-sans-serif, "Helvetica Neue", Helvetica, Arial, sans-serif);
@font-family-base:              @font-family-sans-serif;

@font-size-base:                13px;
@font-size-large:               ceil(@font-size-base * 1.25); // ~18px
@font-size-small:               ceil(@font-size-base * 0.85); // ~12px

@line-height-base:              1.538461538461538; // 20/14
@line-height-computed:          floor((@font-size-base * @line-height-base)); // ~20px

@padding-base-vertical:         6px;
@padding-base-horizontal:       12px;

@padding-large-vertical:        10px;

@padding-small-vertical:        5px;

@line-height-large:             1.33;
@line-height-small:             1.5;

@border-radius-base:            0;
@component-active-bg:           @brand-primary;

@caret-width-large:             5px;

@table-cell-padding:            8px;
@table-condensed-cell-padding:  5px;

@table-bg:                      transparent; // overall background-color
@table-bg-hover:                #f5f5f5;
@table-test-warning:            #fcf8e3; // row highlighting color (test data)
@table-test-warning-interact:   #faf2cc; // row highlighting color for hover and select (test data)

@table-border-color:            #ddd; // table and cell border

@btn-default-color:             var(--fbo-theme-btn-default-color, #333);
@btn-default-bg:                var(--fbo-theme-btn-default-bg, #fff);
@btn-default-border:            var(--fbo-theme-btn-default-border, #ccc);

@btn-primary-color:             #fff;
@btn-primary-bg:				@primary-color;
@btn-primary-border:            var(--fbo-theme-btn-primary-border, darken(#1e65e113, 5%));

@btn-primary-hover-bg:			var(--fbo-theme-btn-primary-hover-bg, #255cbd);
@btn-primary-hover-border:		var(--fbo-theme-btn-primary-hover-border, #1b5acb);

@btn-info-bg:                   @brand-info;
@btn-info-border:               var(--fbo-theme-brand-info, darken(#5bc0de, 5%));

@accent-color:                  var(--fbo-theme-accent-color, #f8b91e);     
@headstone-border-color:        var(--fbo-theme-btn-headstone-border-color, @accent-color);
@site-header-background:        var(--fbo-theme-site-header-background, #fff);
@site-header-color:             var(--fbo-theme-site-header-color, #9d9d9d);

@input-bg:                      var(--fbo-theme-input-bg, #fff);

@input-color:                   @gray;
@input-border:                  #ccc;
@input-border-focus:            #66afe9;

@input-color-placeholder:       @gray-light;

@dropdown-border:               rgba(0,0,0,.15);
@dropdown-link-color:           @gray-dark;
@dropdown-link-hover-color:     #fff;
@dropdown-caret-color:          #000;

@zindex-toast:                  1506;
@zindex-file-upload:            1505;
@zindex-phx-slider:             1504;
@zindex-modal:                  1504;
@zindex-dx-overlay:             1505;
@zindex-header:                 1504;
@zindex-cdk-overlay:            1504;
@zindex-navbar:                 1503;
@zindex-loading-overlay:        1502;
@zindex-dropdown:               1000;
@zindex-popover:                1010;
@zindex-tooltip:                1030;
@zindex-navbar-fixed:           1030;
@zindex-modal-background:       1040;

// Extra small screen / phone
@screen-xs:                     480px;
@screen-phone:                  @screen-xs;

// Small screen / tablet
@screen-sm:                     768px;
@screen-tablet:                 @screen-sm;

// Medium screen / desktop
@screen-md:                     992px;
@screen-desktop:                @screen-md;

// Large screen / wide desktop
@screen-lg:                     1280px;
@screen-lg-desktop:             @screen-lg;

// So media queries don't overlap when required, provide a maximum
@screen-xs-max:                 (@screen-sm - 1);
@screen-sm-max:                 (@screen-md - 1);
@screen-md-max:                 (@screen-lg - 1);


// Number of columns in the grid system
@grid-columns:                  12;
// Padding, to be divided by two and applied to the left and right of all columns
@grid-gutter-width:             30px;
// Point at which the navbar stops collapsing
@grid-float-breakpoint:         @screen-tablet;


// Navbar
// -------------------------

// Basics of a navbar
@navbar-height:                             62px;

// Tabs
@nav-tabs-link-hover-border-color:          @gray-lighter;
@nav-tabs-active-link-hover-bg:             @body-bg;

// Pills
@nav-pills-active-link-hover-color:         #fff;

@state-success-text:                        #468847;
@state-info-text:                           #3a87ad;
@state-info-bg:                             #d9edf7;

// Compliancy colors
@color-warn:                                var(--fbo-theme-color-warn, #ed6c02);
@color-critical:                            var(--fbo-theme-color-critical, #d13031);
@color-normal:                              var(--fbo-theme-color-normal, #5e6e90);
@color-success:                             var(--fbo-theme-color-success, #0f9524);
@color-disabled:                            var(--fbo-theme-color-disabled, #adadad);

@background-color-warn:                     var(--fbo-theme-background-color-warn, #fffbda);
@background-color-critical:                 var(--fbo-theme-background-color-critical, #fff4f4);
@background-color-success:                  var(--fbo-theme-background-color-success, #ebf7ed);

@label-success-bg:                          @brand-success;
@label-info-bg:                             @brand-info;
@label-warning-bg:                          @brand-warning;
@label-danger-bg:                           @brand-danger;

@label-color:                               #fff;
@list-group-border:                         #ddd;
@badge-bg:                                  @gray-light;
@breadcrumb-bg:                             #f5f5f5;
@page-header-border-color:                  @gray-lighter;
@hr-border:                                 @gray-lighter;
@container-tablet:                          ((720px + @grid-gutter-width));

// Medium screen / desktop
@container-desktop:                         ((940px + @grid-gutter-width));

// Large screen / wide desktop
@container-lg-desktop:                      ((1140px + @grid-gutter-width));

/* End bootstrap */

//
// Variables
// --------------------------------------------------
@screen-xs-min:                             (@screen-xs + 1); //!ignore
@screen-sm-min:                             (@screen-sm + 1); //!ignore
@screen-md-min:                             (@screen-md + 1); //!ignore
@screen-lg-min:                             (@screen-lg + 1); //!ignore
@screen-topbar-down:                        460px; //The point to move top menu buttons down in default layout
@screen-topbar-down-min:                    (@screen-topbar-down + 1); //!ignore

@screen-tiny:                               320px; //!ignore

@brand-primary-light:                       var(--fbo-theme-brand-primary-light, lighten(spin(#428bca, 5), 10%));
@brand-primary:                             var(--fbo-theme-brand-primary-light, #428bca);
@brand-success:                             var(--fbo-theme-brand-success, #5cb85c);
@brand-warning:                             var(--fbo-theme-brand-warning, #f0ad4e);
@brand-danger:                              var(--fbo-theme-brand-danger, #d9534f);
@brand-info:                                var(--fbo-theme-brand-info, #5bc0de);
@brand-primary-accent:                      var(--fbo-theme-brand-primary-accent, #d35400);
@app-black:                                 var(--fbo-theme-app-black, #1E1E1F);
@app-orange:                                var(--fbo-theme-app-orange, #f77f00);
@black:                                     var(--fbo-theme-black, #000);
@white:                                     var(--fbo-theme-white, #fff);
@blue:                                      var(--fbo-theme-blue, #049cdb);
@green:                                     var(--fbo-theme-green, #46a546);
@red:                                       var(--fbo-theme-red, #9d261d);
@yellow:                                    var(--fbo-theme-yellow, #ffc40d);
@orange:                                    var(--fbo-theme-orange, #f89406);

@form-group-margin-bottom:                  5px;

@line-height-small-computed:                floor((@font-size-small * @line-height-small)); // ~18px
@font-family-open-sans:                     "Open Sans", sans-serif;
@font-family-base:                          @font-family-open-sans;

@font-family-appicon:                       "appicon";

@border-radius-base:                        0;

@input-color-placeholder:                   #bbb;

@grid-gutter-width:                         20px;

@tab-hover-color:                           var(--fbo-theme-tab-hover-color, #4C8FBD);
@accordion-border:                          var(--fbo-theme-accordion-border, #CDD8E3);
@accordion-header-text:                     var(--fbo-theme-accordion-header-text, #478FCA);
@accordion-header-text-hover:               var(--fbo-theme-accordion-header-text-hover, #6EA6CC);
@accordion-header-hover-bg:                 var(--fbo-theme-accordion-header-hover-bg, #F1F8FD);
@accordion-active-bg:                       var(--fbo-theme-accordion-active-bg, #EEF4F9);

@navbar-mh:                                 62px; //navbar's min-height
@navbar-medium-mh:                          111px; //navbar's medium screen size min-height
@navbar-bg:                                 #438EB9; //navbar background color
@navbar-text-color:                         #FFF;
@brand-size:                                24px; //brand logo text size
@black-background:                          #373b3e;
@border-color:                              #c8cbd0;
@menu-button-text:                          "MENU";

@sidebar-mini-navbar-width:                 40px;
@sidebar-width:                             190px; //sidebar width
@sidebar-min-width:                         255px; //sidebar minimum width
@menu-focus-color:                          @white;
@menu-focus-bg-color:                       var(--fbo-theme-menu-focus-bg-color, #2F3948);
@menu-active-color:                         @green;

@submenu-active-left-border:                var(--fbo-theme-submenu-active-left-border, mix(mix(#BCCFE0, #7EAACB), #7EAACB));

@menumin-border:                            @sidebar-bg;

@sidebar-bg:                                var(--fbo-theme-sidebar-background, #354051);
@sidebar-border-right:                      var(--fbo-theme-accordion-active-bg, #CCC);

@menu-bg:                                   @sidebar-bg;
@menu-color:                                var(--fbo-theme-menu-color, #ffffff);
@menu-open-bg:                              @sidebar-bg;
@menu-active-bg:                            var(--fbo-theme-menu-active-bg, #585858);
@menu-width:                                254px;
@menu-selected-background-color:            var(--fbo-theme-menu-selected-background-color, #2f3948);

@submenu-border:                            @sidebar-bg;
@submenu-bg:                                @sidebar-bg;
@submenu-item-color:                        var(--fbo-theme-submenu-item-color, #ffffff);
@submenu-item-border:                       var(--fbo-theme-submenu-item-border, #E4E4E4);
@submenu-item-active-icon:                  var(--fbo-theme-submenu-item-active-icon, #C86139);

@menumin-btn-bg:                            @sidebar-bg;
@menumin-btn-border:                        @sidebar-bg;
@menumin-icon-color:                        @sidebar-bg;
@menumin-icon-border:                       @sidebar-bg;
@menumin-icon-bg:                           @sidebar-bg;
@menumin-bg:                                @sidebar-bg;

@menumin-shadow:                            ~"2px 1px 2px 0 rgba(0,0,0,0.2)";

@shortcuts-bg:                              @sidebar-bg;
@shortcuts-border:                          @sidebar-bg;

//breadcrumb
@breadcrumb-height:                         41px;
@breadcrumb-arrow-color:                    var(--fbo-theme-breadcrumb-arrow-color, #B2B6BF);
@breadcrumb-bg:                             var(--fbo-theme-breadcrumb-bg, #F5F5F5);
@breadcrumb-border:                         var(--fbo-theme-breadcrumb-border, #E5E5E5);
@breadcrumb-text-color:                     var(--fbo-theme-breadcrumb-text-color, #555);
@breadcrumb-link-color:                     var(--fbo-theme-breadcrumb-link-color, #4C8FBD);
@breadcrumb-separator:                      "\f105"; //font awesome icon

@navbar-inverse-content-bg:                 #fff;
@navbar-inverse-brand-bg:                   #fff;

@state-error-text:                          var(--fbo-theme-state-error-text, #b94a48);

@tag-primary-color:                         var(--fbo-theme-tag-primary-color, lighten(#d35400,30%));

@border-color:                              var(--fbo-theme-border-color, #ddd);
@sidebar-width:                             255px;

@error-border-color:                        var(--fbo-theme-error-border-color, #ee5f5b);
@error-focus-text-color:                    var(--fbo-theme-error-focus-text-color, #b94a48);

@task-list-header-height:                   75px;
@task-padding:                              15px;
