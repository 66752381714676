import { Injectable } from '@angular/core';
import { BehaviorSubject, lastValueFrom, Observable, switchMap, tap } from 'rxjs';

import { NavigationDto } from '@common/data-services/navigation/models/navigation.dto';
import { NavigationDataService } from '@common/data-services/navigation/navigation-data.service';

import { UserProfileNavigationHistory } from './model';
import { ApiService } from '../common';
import { AuthService } from '../common/services/auth.service';

@Injectable()
export class SidenavService {
  constructor(
    private apiService: ApiService,
    private authService: AuthService,
    private navigationDataService: NavigationDataService
  ) {
    /** NOTE: almost certain this isnt needed since profile changes result in a reloading of the app - verify unknown scenarios - feb/25 */
    this.authService.subscribeProfileChanges(() => this.loadNavigationData());
  }

  private navigation$: BehaviorSubject<NavigationDto> = new BehaviorSubject(null);
  public navigationData$ = this.navigation$.asObservable();

  public getNavigationData(): Observable<NavigationDto> {
    return this.navigation$.getValue() ? this.navigationData$ : this.loadNavigationData();
  }

  public saveUserProfileNavigationHistory(navigationId: number, userProfileId: number) {
    // don't care if this results in error, just need a useless catch for promise rejection
    this.apiService.command('UserProfileNavigationHistorySave', {
      UserProfileId: userProfileId,
      NavigationId: navigationId
    }, false).catch(() => { });
  }

  public async getUserProfileNavigationHistoryLastNavigationId(userProfileId: number): Promise<UserProfileNavigationHistory> {
    return lastValueFrom(this.apiService.query<UserProfileNavigationHistory>('UserProfile/getUserProfileNavigationHistoryLastItemByUserProfileId/' + userProfileId, false));
  }

  private loadNavigationData(): Observable<NavigationDto> {
    return this.navigationDataService.getNavigationData().pipe(
      tap(navigation => {
        this.navigation$.next(navigation);
      }),
      switchMap(() => this.navigationData$)
    );
  }
}
