app-global-search {
  .input-group {
    display: flex;
  }

  .input-group-addon {
    width: auto;
  }

  .gs-bar-container {
    position: relative;
    top: 10px;
  }

  @media screen and (max-width: @screen-md) {
    .gs-bar-container {
      top: 0;
    }
  }

  .global-search-input {
    font-size: 13px!important;
  }

  .gs-module-selection {
    width: 150px;
  }

  .gs-search-bar {
    position: relative;
    flex: 1;

    .search-btn {
      box-shadow: none;
      position: relative;
      top: 2px;
      right: -4px;
    }
  }

  .search-hint-title {
    font-weight: bold;
    margin-left: 22px;
    margin-top: 20px;
  }

  .search-hint {
    li {
      margin-top: 10px;

      &:first-child {
        margin-top: 20px;
      }

      &:last-child {
        margin-bottom: 20px;
      }
    }
  }

  .search-last-hint {
    font-style: italic;
    margin-left: 22px;
  }

  .search-all-suggestion {
    cursor: pointer;
    padding: 10px;
    background-color: #ececec;
    font-weight: bold;
  }

  :host ::ng-deep.mat-mdc-form-field {
    .mat-mdc-form-field-wrapper {
      padding-bottom: 0 !important;
    }
  }

  .full-width {
    width: 100% !important;
    background-color: #fff;
  }

  .gs-search-suggestions {
    position: absolute;
    top: 40px;
    left: 0;
    width: 100%;
    background-color: var(--fbo-theme-body-background, #fff);
    z-index: 100;
    max-height: 500px;
    overflow-y: auto;

    .card-content {
      cursor: pointer;
      padding: 10px;
      transition-duration: 0.3s;
      transition-property: background-color;

      &:hover {
        background-color: #f5f5f5;
      }
    }
  }

  .mat-mdc-raised-button {
    border-radius: 2px !important;
  }

  .mat-mdc-form-field-wrapper {
    margin: 0 !important;
    padding-bottom: 0 !important;
  }

  mat-mdc-form-field-infix .mat-mdc-form-field-infix {
    padding: 4px 0;
    height: 41px;
  }

  .mat-mdc-form-field-outline-start {
    border-radius: 0 !important;
  }

  .mat-mdc-form-field-outline {
    color: #bdc5d5;
  }

  .mat-mdc-form-field-outline-thick {
    .mat-mdc-form-field-outline-start,
    .mat-mdc-form-field-outline-end,
    .mat-mdc-form-field-outline-gap {
      border-width: 1px !important;
    }
  }

  &.mat-mdc-focused {
    .mat-mdc-form-field-outline-thick {
      color: #1e64e1;
    }
  }

  .mat-mdc-form-field-suffix {
    top: 0 !important;

    button {
      line-height: 30px;

      .mat-icon {
        display: inline-block !important;
      }
    }
  }

  img {
    margin-bottom: 2px;
  }

  @import '../../shared/css/shared';
}

.option-select {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right: none !important;
}

.clear-button {
  position: absolute;
  right: 65px;
  z-index: 10;
  width: 20px;
  top: -3px;

  mat-icon {
    height: 27px;
  }
}

.search-icon-button {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;

  position: relative;
  padding: 5px 10px 4px 13px;
  right: -16px;

  &:hover {
    cursor: pointer;
  }
}

.search-input-container {
  position: relative;
  width: 100%;
}

.mat-mdc-form-field-outline-end {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
