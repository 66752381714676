export const TopNavMenuResourceKeys = {
  manageAccountLabel: 'account.menu.manageAccountLabel',
  settingsLabel: 'account.menu.settingsLabel',
  signOutLabel: 'account.menu.signOutLabel',
  menuLabel: 'account.menu.menuLabel',
  editFavoritesLabel: 'account.menu.editFavoritesLabel',
  addToFavoritesLabel: 'account.menu.addToFavoritesLabel',
  userGuidesLabel: 'account.menu.userGuidesLabel',
  supportTicketLabel:'account.menu.supportTicketLabel',
  downloads: 'common.generic.downloads'
} as const;
