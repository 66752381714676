export var setConfig = function (config) {
  return {
    type: "SET_CONFIG",
    config: config
  };
};
export var setSearchCallback = function (searchCallback) {
  return {
    type: "SET_SEARCH_CALLBACK",
    searchCallback: searchCallback
  };
};
export var setSuggestCallback = function (suggestCallback) {
  return {
    type: "SET_SUGGEST_CALLBACK",
    suggestCallback: suggestCallback
  };
};
