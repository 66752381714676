@import "../../../../../less/variables";

.gs-suggestion-row {
  display: inline-flex;
  width: 100%;
};

.gs-suggestion-row > div {
  width: 100%;
}

.gs-suggestion-assignment-card-content, .gs-suggestion-payment-card-content,
.gs-suggestion-trn-card-content {
  line-height: 200%;
  cursor: pointer;
  padding: 10px;
  transition-duration: 0.3s;
  transition-property: background-color;

  &:hover {
    background-color: #f5f5f5;
  }
}

.gs-suggestion-worker-info, .gs-suggestion-wo-info,
.gs-suggestion-wo-period, .gs-suggestion-payment-total,
.gs-suggestion-payment-worker-details, .gs-suggestion-payment-period,
.gs-suggestion-payment-info, .gs-suggestion-trn-info,
.gs-suggestion-trn-period, .gs-suggestion-trn-worker-details,
.gs-suggestion-trn-total {
  float: left;
  font-family: @font-family-open-sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  color: #1E2430;

  .gs-suggestion-payment-worker-name, .gs-suggestion-trn-worker-name {
    font-weight: bold;
  }
}

.gs-suggestion-wo-info, .gs-suggestion-payment-info,
.gs-suggestion-payment-period, .gs-suggestion-trn-info {
  font-size: 13px;
}

.gs-suggestion-wo-period, .gs-suggestion-payment-total,
.gs-suggestion-payment-period, .gs-suggestion-trn-period, .gs-suggestion-trn-total {
  float: right;
  text-align: right;
  font-size: 13px;
}

.gs-suggestion-assignment-clientname, .gs-suggestion-payment-clientname, .gs-suggestion-trn-clientname {
  font-size: 13px;
  float: left;
  color: #1E2430;
}

.gs-suggestion-assignment-wostatus, .gs-suggestion-payment-paymentstatus, .gs-suggestion-trn-trnstatus {
  font-size: 13px;
  float: right;
  text-align: right;
  color: #1E2430;
}

.circle {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
}

.active-status {
  background: #09C68D;
}

.processing-status {
  background: #ECBB0D;
}

.complete-status {
  background: #BDC5D5;
}

.searchicon {
  display: inline;
  color: #1E64E1;
}

.searchtext {
  padding-left: 10px;
  display: inline;
  font-size: 13px;
  color: #506080;

  ::ng-deep b {
    background-color: yellow;
  }
}

.gs-payment-suggestion-pipe {
  font-size: 13px;
  color: #506080
}

.gs-trn-suggestion-pipe {
  font-size: 16px;
  color: #506080
}
