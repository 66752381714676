export var setSuggestionsApiVersion = function (apiVersion) {
  return {
    type: "SET_SUGGESTIONS_APIVERSION",
    apiVersion: apiVersion
  };
};
export var setSuggestionsParameters = function (parameters) {
  return {
    type: "SET_SUGGESTIONS_PARAMETERS",
    parameters: parameters
  };
};
export var updateSuggestionsParameters = function (parameters) {
  return {
    type: "UPDATE_SUGGESTIONS_PARAMETERS",
    parameters: parameters
  };
};
