import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';

import { DxDropDownButtonModule } from 'devextreme-angular';

import { PhoenixCommonModule } from '@common/PhoenixCommon.module';
import { DownloadCenterButtonModule } from '@download-center/download-center-button/download-center-button.module';
import { GlobalSearchBarModule } from '@global-search/global-search-bar/global-search-bar.module';

import { TopNavMenuComponent } from './top-nav-menu.component';

@NgModule({
  declarations: [TopNavMenuComponent],
  exports: [
    TopNavMenuComponent
  ],
  imports: [
    CommonModule,
    PhoenixCommonModule,
    ReactiveFormsModule,
    GlobalSearchBarModule,
    DownloadCenterButtonModule,
    MatDialogModule,
    DxDropDownButtonModule,
    RouterModule
  ]
})
export class TopNavMenuModule {
}
