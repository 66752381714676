<div class="breadcrumbs " id="breadcrumbs">
  <div class="page-title">
      <span *ngFor="let decorator of pageTitleDecorators">
          <i *ngIf="decorator.ShowIcon" 
            class="material-icons page-icon breadcrumb-icon" 
            [ngClass]="{'iconMarginRight': !decorator.ShowText}" >
            {{decorator.Icon}}
          </i>
          <span *ngIf="decorator.ShowText" class="page-text">
            {{decorator.Text}}
          </span>
          <i *ngIf="decorator.ShowText" 
            class="material-icons breadcrumb-spacer">
            keyboard_arrow_right
          </i>
      </span>
  </div>
</div>
